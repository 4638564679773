// Generated by Framer (f26e712)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["KTHclA_ym", "W8r8zwETw"];

const serializationHash = "framer-Sd69O"

const variantClassNames = {KTHclA_ym: "framer-v-1usovu", W8r8zwETw: "framer-v-1gi0f1v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 1, ease: [0.85, -0.02, 0.31, 0.96], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {End: "W8r8zwETw", Start: "KTHclA_ym"}

const getProps = ({color, height, id, opacity, width, ...props}) => { return {...props, eV0W9xNCm: opacity ?? props.eV0W9xNCm ?? 0.12, L7khgg0dA: color ?? props.L7khgg0dA ?? "rgb(0, 0, 0)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "KTHclA_ym"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;color?: string;opacity?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, L7khgg0dA, eV0W9xNCm, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "KTHclA_ym", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1usovu", className, classNames)} data-framer-name={"Start"} layoutDependency={layoutDependency} layoutId={"KTHclA_ym"} ref={refBinding} style={{...style}} {...addPropertyOverrides({W8r8zwETw: {"data-framer-name": "End"}}, baseVariant, gestureVariant)}><motion.div className={"framer-14cnlj3"} layoutDependency={layoutDependency} layoutId={"V6ORrYAOD"} style={{backgroundColor: L7khgg0dA, opacity: 1}} variants={{W8r8zwETw: {opacity: eV0W9xNCm}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Sd69O.framer-1x809v9, .framer-Sd69O .framer-1x809v9 { display: block; }", ".framer-Sd69O.framer-1usovu { height: 1px; overflow: visible; position: relative; width: 200px; }", ".framer-Sd69O .framer-14cnlj3 { flex: none; height: 1px; left: 0px; overflow: hidden; position: absolute; top: 0px; width: 1px; }", ".framer-Sd69O.framer-v-1gi0f1v .framer-14cnlj3 { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"W8r8zwETw":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"L7khgg0dA":"color","eV0W9xNCm":"opacity"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruAsrSsx9N: React.ComponentType<Props> = withCSS(Component, css, "framer-Sd69O") as typeof Component;
export default FrameruAsrSsx9N;

FrameruAsrSsx9N.displayName = "Animated line";

FrameruAsrSsx9N.defaultProps = {height: 1, width: 200};

addPropertyControls(FrameruAsrSsx9N, {variant: {options: ["KTHclA_ym", "W8r8zwETw"], optionTitles: ["Start", "End"], title: "Variant", type: ControlType.Enum}, L7khgg0dA: {defaultValue: "rgb(0, 0, 0)", title: "Color", type: ControlType.Color}, eV0W9xNCm: {defaultValue: 0.12, max: 1, min: 0, step: 0.01, title: "Opacity", type: ControlType.Number}} as any)

addFonts(FrameruAsrSsx9N, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})